@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1180px !important;
  }
}
@media screen and (min-width: 1000px) {
  .resume-form-page .col-lg-6.custom-col-7 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .resume-form-page .col-lg-6.custom-col-5 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-lg-8.col-custom {
    flex: 0 0 70.666667%;
    max-width: 70.666667%;
  }
  /* reusme small css */
  .resume-zoom .top-heading {
    padding: 32px;
    font-size: 15px;
    line-height: 13px;
    padding-top: 30px;
    min-height: 11px;
  }
  .resume-zoom .resume-heading .heading-resume {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .resume-zoom .resume-template-box {
    font-size: 5px;
  }
  .resume-zoom .percent-clr {
    width: 10px;
  }
  .resume-zoom ul {
    padding-left: 10px !important;
    margin-bottom: 5px;
  }
  .resume-zoom .pt-5 {
    padding-top: 5px !important;
  }
  .resume-zoom hr {
    margin-top: 5px;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .resume-zoom .clr-dark {
    padding: 5px;
  }
  .resume-zoom .resume-icon {
    padding-right: 2px;
    display: inline-block;
  }
  .resume-zoom .socail-info p {
    position: relative;
    padding-left: 10px;
  }
  .resume-zoom.preview-box .socail-info p {
    padding-left: 15px;
  }
  .resume-zoom .resume-template-box {
    padding: 8px 3%;
  }
  .resume-zoom .pt-4 {
    padding-top: 5px !important;
  }
  .resume-zoom .clr-dark {
    padding: 6px !important;
  }
  .resume-zoom .td-left {
    padding-right: 10px !important;
  }
  .resume-zoom .mb-2 {
    margin-bottom: 0px !important;
  }
  .resume-zoom .resume-third-temp .resume-heading:after .heading-resume:after {
    top: -1px;
    height: 10px;
  }
  .resume-zoom .resume-third-temp .resume-heading .heading-resume {
    padding-left: 10px;
  }
  /* template two */
  .resume-zoom .h1,
  .resume-zoom h1 {
    font-size: 10px;
    margin-bottom: 5px;
    word-break: break-word;
  }
  .resume-zoom .Name-heading {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .resume-zoom .Name-heading:after {
    width: 15px;
    height: 2px;
  }
  .resume-zoom .resume-icon.box {
    padding-right: 0px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    margin-right: 2px;
    position: absolute;
    left: 0;
  }
  .resume-zoom .resume-sec-temp .socail-info p {
    margin-bottom: 5px !important;
    padding-left: 14px;
  }
  .resume-zoom .resume-sec-temp .mb-5 {
    margin-bottom: 1rem !important;
  }
  /* third template css */
  .resume-zoom .social-info-third p {
    margin-bottom: 2px;
  }
  .resume-zoom .resume-heading-third:after {
    top: -1px;
    height: 10px;
  }
  .resume-zoom .resume-heading-third {
    padding-left: 10px;
  }
  .resume-zoom .summay-box,
  .resume-zoom .skill-list,
  .resume-zoom .work-history-box,
  .resume-zoom .socail-info {
    margin-bottom: 10px !important;
  }
  .resume-zoom .resume-third-temp .mb-5 {
    margin-bottom: 10px !important;
  }
  /* changes css */
  .footer-bootom .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-screen .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  /* new resume template css */
  .template-section .resume-zoom .top-section,
  .resume-preview .resume-zoom .top-section {
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .template-section .resume-zoom .name-title .title-name,
  .resume-preview .resume-zoom .name-title .title-name {
    font-size: 16px;
    margin-bottom: 0px;
    padding-bottom: 7px;
  }
  .template-section .resume-zoom .resume-heading .heading-resume {
    font-size: 9px;
    font-weight: 700 !important;
  }
  .template-section .resume-zoom .parent-right {
    width: 66%;
    padding-left: 15px;
  }
  .template-section .resume-zoom .parent-left,
  .resume-preview .resume-zoom .parent-left {
    width: 34%;
    padding-right: 0px;
  }
  .template-section .resume-zoom .pd-bg-section,
  .resume-preview .resume-zoom .pd-bg-section {
    padding: 15px;
    padding-left: 0px;
  }
  .template-section .resume-zoom .resume-ml,
  .resume-preview .resume-zoom .resume-ml {
    margin-left: -15px;
    padding-left: 10px;
    margin-right: -15px;
  }
  .template-section .resume-zoom .parent-container .border-left,
  .resume-preview .resume-zoom .parent-container .border-left {
    border-left: 1px solid #000 !important;
  }
  .template-section .resume-zoom .parent-container,
  .resume-preview .resume-zoom .parent-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .template-section .resume-zoom .resume-template-box,
  .resume-preview .resume-zoom .resume-template-box {
    font-size: 7.5px;
    font-weight: 500;
  }
  .template-section .resume-zoom .pt-30,
  .resume-preview .resume-zoom .pt-30 {
    padding-top: 15px;
  }
  .template-section .resume-zoom .resume-table.pb-5,
  .resume-preview .resume-zoom .resume-table.pb-5 {
    padding-bottom: 10px !important;
  }
  .template-section .resume-zoom .resume-template-box,
  .resume-preview .resume-zoom .resume-template-box {
    padding: 8px;
  }
  .template-section .resume-zoom .resume-sec-temp .socail-info p,
  .resume-preview .resume-zoom .resume-sec-temp .socail-info p {
    margin-bottom: 3px !important;
    padding-left: 0px;
  }
  .resume-preview .resume-zoom .resume-heading .heading-resume {
    font-size: 8px;
  }
  .resume-preview .resume-zoom .parent-right {
    padding-left: 10px;
    width: 66%;
  }
  .resume-zoom .resume-content-box {
    display: flex;
    justify-content: center;
    /* height: 100%;
    overflow: hidden; */
    padding-left: 15px;
    padding-right: 15px;
  }
  .resume-preview .resume-zoom .resume-template-box {
    height: 630px;
    overflow: hidden;
    overflow-y: auto;
  }
  .resume-preview .resume-zoom .preview-inner {
    height: auto;
  }
  .resume-zoom .temp-3 .socail-info {
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
  .resume-zoom .temp-3 .top-fix-box {
    padding: 3% 8px;
    padding-left: 15px;
  }
  .resume-zoom .temp-3 .resume-content-three {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .resume-zoom .temp-3 .resume-heading:before .heading-resume:before {
    content: "";
    left: -15px;
    height: 8px;
    width: 8px;
  }
  .resume-temp .resume-zoom .preview-inner {
    height: 432px;
    overflow: hidden;
  }
  .resume-temp .resume-zoom .resume-template-box .preview-inner {
    height: 454px;
  }
  .preview-box .resume-content-one .parent-col:nth-of-type(2),
  .preview-box .resume-content-one .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
  .resume-zoom.preview-box .resume-icon svg {
    width: 9px;
    height: 9px;
  }

  .resume-zoom.preview-box .resume-content-one .parent-col:nth-of-type(2) {
    padding-right: 10px;
    padding-left: 10px;
  }
  .resume-zoom.preview-box .resume-content-three {
    margin-top: 0px;
    margin-left: 0px;
  }
  .resume-zoom.preview-box .resume-content-three .parent-col:nth-of-type(2) {
    padding-top: 0px !important;
  }
  .resume-zoom.preview-box
    .temp-4-pd
    .resume-content-box
    .parent-col:nth-of-type(1) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1150px) {
  .resume-info-container h6 {
    font-size: 15px;
  }
  .resume-info {
    font-size: 10px;
  }
  .doc-Strength-label {
    font-size: 10px;
  }
  .doc-strength-value {
    width: 30px;
    height: 16px;
    line-height: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
}
@media screen and (max-width: 1000px) {
  .bg-double:after {
    display: none;
  }
  .resume-preview.p-5 {
    padding: 0px !important;
  }
  .pre-btn.text-right {
    display: none;
  }
  .screen-left {
    padding-left: 0%;
    padding-right: 0%;
  }
  .right-box-screen {
    justify-content: center;
    margin-bottom: 30px;
    min-height: 430px !important;
  }
  .screen-right {
    padding-left: 0%;
    padding-top: 0px;
  }
  .condition-box {
    padding-bottom: 20%;
  }
  .progress-section {
    display: flex;
    flex-wrap: wrap;
  }
  .progress-section .step {
    margin-bottom: 7px;
  }
  .resume-contact-section .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .resume-preview.text-right {
    /* text-align: center !important; */
    margin-top: 30px;
  }
  .pre-btn.text-center {
    padding-left: 0px !important;
  }
  .resume-preview img {
    height: auto;
  }
  .custom-pd-right {
    padding-right: 0;
  }
  .custom-pd-left {
    padding-left: 0;
  }
  .brand-logo a {
    margin-bottom: 15px;
    display: inline-block;
  }
  .mob-show {
    display: block;
  }
  .mob-hide {
    display: none !important;
  }
  .summary-box:after,
  .summary-box:before {
    display: none;
  }
  .extra-section-preview {
    padding-top: 10%;
  }
  .custom-add-section-list {
    padding-top: 10%;
  }
  .template,
  .formate {
    width: auto;
  }
  .sidebar-link {
    width: 100%;
    margin-bottom: 30px;
  }
  .main-resume {
    margin: 0px 0%;
  }
  .resume-zoom.pl-5 {
    padding-left: 0px !important;
  }
  /* resume slider zoom property start */
  .resume-slide-box .resume-zoom .top-heading {
    padding: 7px;
    font-size: 8px;
    line-height: 4px;
  }
  .resume-slide-box .resume-zoom .resume-heading .heading-resume {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    font-size: 5px;
  }
  .resume-slide-box .resume-zoom .percent-clr {
    width: 5px;
    height: 1.2px;
  }
  .resume-slide-box .resume-zoom ul {
    padding-left: 10px !important;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .pt-5 {
    padding-top: 5px !important;
  }
  .resume-slide-box .resume-zoom hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .clr-dark {
    padding: 5px;
  }
  .resume-slide-box .resume-zoom .resume-icon {
    padding-right: 2px;
    display: inline-block;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    padding: 8px 3%;
  }
  .resume-slide-box .resume-zoom .pt-4 {
    padding-top: 5px !important;
  }
  .resume-slide-box .resume-zoom .clr-dark {
    padding: 6px !important;
  }
  .resume-slide-box .resume-zoom .td-left {
    padding-right: 10px !important;
  }
  .resume-slide-box .resume-zoom .mb-2 {
    margin-bottom: 0px !important;
  }
  /* template two */
  .resume-slide-box .resume-zoom .h1,
  .resume-slide-box .resume-zoom h1 {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .Name-heading {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .Name-heading:after {
    width: 15px;
    height: 2px;
  }
  .resume-slide-box .resume-zoom .resume-icon.box {
    padding-right: 0px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    margin-right: 2px;
  }
  .resume-slide-box .resume-zoom .resume-sec-temp .socail-info p {
    margin-bottom: 3px !important;
  }
  .resume-slide-box .resume-zoom .resume-sec-temp .mb-5 {
    margin-bottom: 1rem !important;
  }
  /* third template css */
  .resume-slide-box .resume-zoom .social-info-third p {
    margin-bottom: 2px;
  }
  .resume-slide-box .resume-zoom .resume-heading-third:after {
    top: -1px;
    height: 10px;
  }
  .resume-slide-box .resume-zoom .resume-heading-third {
    padding-left: 10px;
  }
  .resume-slide-box .resume-zoom .summay-box,
  .resume-slide-box .resume-zoom .skill-list {
    margin-bottom: 10px !important;
  }
  .resume-slide-box .resume-zoom .resume-third-temp .mb-5 {
    margin-bottom: 10px !important;
  }
  /* resume slider zoom property end */
  /* language slider */
  .lang-range-slider {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    margin-bottom: 10%;
  }
  /* payment css */

  .payment-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .plan-box.left-section.sm-sec,
  .plan-box.left-section.middle-section {
    width: 100%;
    margin-bottom: 20px;
    min-height: auto;
  }
  /* customer login css */
  .custom-table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 2px;
  }
  .account-content-box {
    border-left: none;
    padding-left: 0;
    margin-top: 20px;
  }
  .account-info-list li .account-info-item,
  .account-info-list li .account-info,
  .account-info-list li .link {
    min-width: 100%;
    display: block;
  }
  .subscription-box {
    flex-direction: column;
  }
  .contactus-info {
    border-left: none;
    padding: 0;
    border-left: 0;
    border-top: 1px solid #9b9b9b;
    padding-top: 20px;
    margin-top: 20px;
  }
  .subscription-box {
    background-color: #f4f5fb;
    padding: 1.5rem 1rem;
  }
  .resume-temp .resume-zoom .preview-inner {
    height: 860px;
  }
  .resume-preview .resume-zoom .preview-inner {
    height: 790px;
    overflow: hidden;
  }
  .left-box-screen,
  .right-box-screen {
    min-height: calc(100vh - 620px) !important;
  }
  .resume-preview .pl-3 {
    padding-left: 0px !important;
  }
  .width-btn {
    min-width: auto;
  }
  .mpd-5 {
    margin-top: 30px;
  }
  .resume-slide-box .resume-content-box .parent-col {
    min-height: 300px;
  }
  .resume-icon svg {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 767px) {
  .flex-direction {
    flex-direction: column-reverse;
  }
  .footer-choose-btn .site-btn {
    padding: 12px 12px !important;
  }
  .editor-wrapper {
    border: none;
  }
  .list-editor,
  .draft-editor-box {
    width: 100%;
    margin-bottom: 25px;
  }
  .list-editor {
    border: 1px solid var(--light-grey);
  }
  .para-toolbar a {
    padding: 0px;
    font-size: 14px;
    margin: 4px !important;
  }
  .paragroup-item {
    padding: 40px 20px 0 20px;
  }
  .site-btn {
    padding: 12px 25px !important;
  }
  .modal.show .more-info-modal {
    margin-top: 1.75rem !important;
  }
  .more-info-modal .modal-body {
    padding: 40px 25px;
  }
  .custom-modal-footer {
    padding: 30px 30px;
    padding-top: 0px;
  }
  .fiter-search-left,
  .fiter-search-right {
    width: 100%;
  }
  .width-btn {
    min-width: auto;
  }
  .drop-down-template {
    margin-bottom: 5px;
    padding: 13px 8px !important;
    justify-content: center;
  }
  .footer-top {
    padding-bottom: 40px;
  }
  .bottom-fix-toolbar {
    padding: 6px !important;
  }
  .popover-box-inner {
    border-radius: 0;
    width: 270px;
  }
  /* customer admin css */
  .create-resume-link {
    margin-top: 15px;
  }
  .h1,
  h1 {
    font-size: 1.5rem !important;
  }
  /* language slider */
  .range-text {
    font-size: 12px;
  }
  .lang-range-slider {
    width: 90%;
  }
  .proficency-range .MuiSlider-markLabel {
    top: -20px !important;
  }
  .range-text .label-value {
    left: -35px;
    top: 50px;
  }
  .resume-zoom.pl-3 {
    padding-left: 0px !important;
  }
  /* .resume-slide-box .resume-heading {
    font-size: 8px !important;
} */

  .resume-slide-box .resume-preview .resume-zoom .resume-template-box {
    height: 400px;
    overflow: hidden;
  }
  .resume-slide-box .resume-preview .resume-zoom .preview-inner {
    height: 385px;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    padding: 8px;
  }
  .resume-slide-box .resume-zoom .resume-heading .heading-resume {
    font-size: 14px !important;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    font-size: 10px;
  }
  .resume-slide-box .resume-zoom .name-title .title-name {
    font-size: 18px !important;
  }
  .resume-slide-box .resume-zoom .top-fix-box {
    padding-left: 35px;
  }
  .resume-slide-box .resume-zoom .top-heading {
    padding: 14px;
  }
  .parent-col:nth-of-type(2) {
    padding-left: 15px;
  }
  .formating-box {
    left: -70%;
  }
  .resume-slide-box .resume-zoom .top-section {
    padding: 0px !important;
    padding-bottom: 0px !important;
  }
  .resume-slide-box .resume-preview .pl-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .resume-slide-box
    .styleTempDBTen
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right,
  .resume-slide-box
    .styleTempDBEleven
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right {
    padding-left: 34%;
  }
  .scroll-resume-box .styleTempDBNine .dummy-img-box {
    top: 10px;
  }
  .scroll-resume-box .resume-zoom .temp-3 .top-fix-box {
    padding: 2% 15px !important;
    padding-left: 15px !important;
  }
  /* active language slider */
  .active-slide .paragroup-item {
    padding: 20px 20px 0 30px;
  }
  .active-slide .lang-range-slider {
    width: 100%;
  }
  .active-slide .para-toolbar {
    top: 8px;
  }
  .cart-modal-footer h4 {
    font-size: 17px !important;
  }
  .header.border-bottom {
    padding: 0px 10px;
  }
  .brand-logo img {
    height: 35px;
  }
  .popup-body .grid.grid-cols-2 {
    padding: 30px 30px;
  }
  .heading,
  .chosse-page-title {
    font-weight: 600 !important;
  }
  .exp-content-box {
    margin-top: 0vh;
    padding-top: 2rem !important;
  }
  .exp-content-box .mb-5 {
    margin-bottom: 35px !important;
  }
  .mob-col {
    width: 50% !important;
  }
  .resume-template-box ul {
    padding-left: 9px;
    word-wrap: break-word;
  }
  .resume-heading {
    font-size: 12px !important;
    margin-bottom: 9px;
  }
  .paragraph {
    font-size: 8px;
  }
  div#socialInfo p {
    min-height: 10px;
    word-break: break-all;
  }
  .resume-icon {
    padding-right: 5px;
  }
  .styleTempDBNine .parent-col:nth-of-type(1) .parent-right .name-title {
    padding-left: 8px;
    padding-top: 6px;
    margin-bottom: 0px;
  }
  .template-section .styleTempDBNine .dummy-img {
    width: 103px !important;
  }
  .resume-zoom.styleTempFive .profile-img-box,
  .resume-zoom.styleTempFour .profile-img-box {
    margin-bottom: 4px;
  }
  .choose-template-section {
    padding-bottom: 30px;
  }
  .resume-select-btn .site-btn {
    min-width: 125px;
    font-size: 9px !important;
    padding: 5px 4px !important;
  }
  .resume-temp .resume-select-btn {
    min-width: 110px;
  }
  .resume-preview {
    display: none;
  }
  .home-btn {
    width: 100%;
    max-width: 100% !important;
  }
  .site-btn {
    min-width: 140px;
  }
  .switch.active .slider::before {
    left: 0px !important;
  }
  .exp-screen-section,
  .choose-template-section {
    min-height: calc(100vh - 160px) !important;
  }
  .step-numbering.mt-1.mb-1 {
    margin-top: 1rem !important;
  }
  .upload-img-input {
    width: 280px;
    height: 308px;
  }
  .text-right.modal-image-btn {
    text-align: left !important;
    display: flex;
    justify-content: space-between;
  }
  h2.heading.text-white.bold {
    font-size: 36px;
  }
  .modal-content-box .modal-body {
    padding: 1rem 1rem;
  }
  .custom-modal-footer .pd-0 {
    padding-right: 10px !important;
  }
  .summary-preview {
    min-height: calc(100vh - 60px) !important;
  }
  .language-screen .pl-0,
  .language-screen .px-0 {
    padding-left: 15px !important;
  }
  .language-screen .pr-2,
  .language-screen .px-2 {
    padding-right: 15px !important;
  }
  .range-text .label-value {
    left: 43%;
  }
  .active-slide .paragroup-item.pd-custom .para-toolbar {
    top: 7px;
    width: 20px;
    height: 20px;
    font-size: 11px;
  }
  .label-name {
    width: 100%;
    overflow: hidden;
    width: 48px;
    text-overflow: ellipsis;
  }
  .proficency-range.mt-4 {
    margin-top: 5px !important;
  }
  .col-sm-12.langg-hd {
    margin-top: 10px;
  }
  .add-section-list .custom-check-container .checkmark {
    position: absolute;
    top: 2px;
  }
  .signature-preview h2.text-white.semi-bold {
    font-size: 28px;
    margin-bottom: 13px;
  }
  .signature-preview .heading.text-white.bold {
    font-size: 18px !important;
  }
  .tab-header button {
    color: #000;
    font-size: 14px;
  }
  .custom-col.formate {
    display: none;
  }
  .bottom-fix-toolbar {
    top: 60px;
  }
  .drop-down-template {
    padding-right: 18px !important;
  }
  .more-color-dropdown:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
  }
  .more-color-dropdown {
    top: 49%;
  }
  .final-structure-container {
    padding-top: 0px !important;
  }
  .after-login-header .container {
    padding: 10px 0px;
  }
  .plan-box.right-section {
    width: 100%;
  }
  .side-content {
    width: 100%;
  }
  h4.tab-title.mb-4 {
    font-size: 1.3rem;
  }
  .tab-list-content a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 3px;
  }
  .list-view-tesume td,
  .list-view-tesume th {
    vertical-align: top;
  }
  /* iphone responsive */
  .resume-template-box {
    zoom: 1;
  }
  .resume-heading {
    font-size: 4px !important;
    margin-bottom: 3px;
  }
  .paragraph {
    font-size: 3px;
  }
  .template-grid h1.name-title {
    font-size: 9px !important;
  }
  .template-grid .styleTempDBEight h1.name-title,
  .template-grid .styleTempDBTen h1.name-title,
  .template-grid .styleTempDBEleven h1.name-title {
    font-size: 9px !important;
    margin-bottom: 0px;
  }
  .template-grid
    .styleTempDBSix
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .resume-zoom .styleTempDBSix .parent-right .name-title {
    font-size: 8px !important;
    margin-bottom: 0px;
    padding-top: 12px;
  }

  .template-grid .styleTempDBSeven .dummy-img,
  .resume-preview .styleTempDBSeven .dummy-img,
  .resume-preview .styleTempDBNine .dummy-img {
    height: 35px !important;
    width: 35px !important;
  }
  .template-section .resume-zoom.styleTempDBSix.resume-zoom .dummy-img,
  .resume-zoom.styleTempDBSix .resume-preview .resume-zoom .dummy-img {
    height: 35px !important;
    width: 35px !important;
  }
  .template-section .resume-zoom .dummy-img,
  .resume-preview .resume-zoom .dummy-img {
    height: 35px;
  }
  .styleTempDBEleven .temp-4-pd .parent-left {
    padding: 10px !important;
  }

  .styleTempDBEight .parent-col:nth-of-type(2),
  .styleTempDBEight .parent-col:nth-of-type(2) {
    padding-top: 30px !important;
  }
  .choose-template-section .temp-4-pd .parent-col:nth-of-type(2) {
    padding-right: 7px;
  }
  .choose-template-section .parent-col:nth-of-type(2) {
    padding-left: 7px;
  }
  .styleTempDBSeven .top-section .parent-right,
  .styleTempDBEight .top-section .parent-right,
  .styleTempDBTen .top-section .parent-right,
  .styleTempDBEleven .top-section .parent-right {
    padding-top: 8px;
  }
  .resume-content-one .parent-col:nth-of-type(3),
  .temp-4-pd .parent-col:nth-of-type(3) {
    padding-left: 7px;
    padding-right: 7px;
  }
  .resume-preview-box .resume-icon svg {
    width: 2px;
    height: 2px;
  }
  div#socialInfo p {
    min-height: 0px;
    word-break: break-all;
    margin-bottom: 0px !important;
  }
  .resume-zoom .styleTempDBEight .dummy-img,
  .template-section .styleTempDBEight .dummy-img {
    height: 45px !important;
  }
  .resume-preview-box .summay-box,
  .socail-info,
  .work-history-box,
  .language-box,
  .skill-list {
    margin-bottom: 7px;
  }
  .styleTempDBSix .parent-col:nth-of-type(1) .parent-right {
    padding-left: 7px;
  }
  .styleTempDBSix .parent-col:nth-of-type(3) {
    padding-top: 8px !important;
  }
  .styleTempDBSix .resume-content-box .parent-col:nth-of-type(2) {
    padding-top: 15px !important;
  }
  .styleTempDBSix .parent-col:nth-of-type(1) .parent-left .profile-pic {
    top: 15px;
  }
  .skill-list-content {
    margin-bottom: 0px;
  }
  .template-section .resume-preview-box .resume-temp {
    min-height: 230px;
  }
  .template-section .resume-preview-box .resume-temp .resume-template-box,
  .template-section
    .resume-preview-box
    .resume-temp
    .resume-template-box.temp-3 {
    height: 235px;
  }
  .template-section .resume-zoom .temp-3 .dummy-img,
  .resume-preview .resume-zoom .temp-3 .dummy-img,
  .template-section .styleTempDBNine .dummy-img {
    height: 40px !important;
    width: 40px !important;
  }
  .styleTempDBTwelve .profile-pic .addNew .dummy-img {
    height: 25px !important;
    width: 25px !important;
  }
  .template-section .styleTempDBTen .temp-4-pd .parent-left {
    padding-right: 0px !important;
    padding: 6px !important;
  }
  .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(1) .parent-right,
  .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(1) .parent-right {
    padding-left: 29%;
  }
  .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 35px !important;
  }
  .resume-zoom.styleTempThree .temp-3 .mb-3.py-2.px-4.name-bg {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .resume-zoom .resume-template-box.temp-3 .socail-info.resume-social-info p {
    font-size: 3px;
    padding-left: 8px;
  }
  .temp-3 .socail-info {
    margin-top: 5px;
  }
  .resume-zoom .resume-template-box.temp-3 .profile-img-box {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .parent-col:last-child {
    padding-top: 8px !important;
  }
  .styleTempOne .resume-section .paragraph,
  .styleTempOne .resume-section .paragraph1,
  .styleTempThree .resume-section .paragraph,
  .styleTempThree .resume-section .paragraph1 {
    padding-left: 15px;
  }
  .resume-zoom.styleTempDBTen .dummy-img,
  .resume-zoom.styleTempDBEleven .dummy-img {
    height: 35px !important;
  }
  .styleTempDBEleven .postition-bg-style.fade-bg {
    height: 128px;
    width: 128px;
  }
  .template-grid .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 30px !important;
  }
  .resume-zoom.styleTempThree .profile-img-box {
    padding-right: 0px !important;
  }
  .template-grid .styleTempThree .contact-info-box {
    padding-left: 0px !important;
  }
  .template-grid .styleTempThree .mb-3.name-bg {
    margin-bottom: 0px !important;
  }
  .template-section .resume-zoom.styleTempThree .temp-3 .dummy-img {
    height: 25px !important;
    width: 25px !important;
  }
  .choose-template-section .percent-box {
    margin-bottom: 2px;
  }
  .choose-template-section p.percent.mb-1 {
    margin-bottom: 2px !important;
  }
  .styleTempDBSeven .top-section .parent-right,
  .styleTempDBEight .top-section .parent-right,
  .styleTempDBTen .top-section .parent-right,
  .styleTempDBEleven .top-section .parent-right {
    padding: 6px;
  }
  .template-grid
    .styleTempDBTwelve
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    font-size: 9px !important;
    font-weight: 600 !important;
    padding-left: 7px;
  }
  .styleTempDBTwelve .profile-pic .dummy-img-box {
    padding-left: 8px;
  }
  .styleTempDBTwelve .top-section.parent-container {
    padding: 7px 0px !important;
    padding-bottom: 7px !important;
  }
  .choose-template-section .styleTempThree .name-bg {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .choose-template-section
    .styleTempDBTwelve
    .temp-4-pd
    .parent-col:nth-of-type(2),
  .choose-template-section
    .styleTempDBTwelve
    .temp-4-pd
    .parent-col:nth-of-type(3) {
    padding-top: 9px !important;
  }
  .choose-template-section
    .template-grid
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .choose-template-section
    .resume-zoom
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    font-size: 9px !important;
  }
  .choose-template-section .styleTempDBSeven .dummy-img-box,
  .styleTempDBNine .dummy-img-box {
    transform: translate(-50%, 0px);
    top: 7px;
  }
  .choose-template-section .styleTempDBSeven .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
  .choose-template-section .styleTempDBNine .parent-col:nth-of-type(2) {
    padding-top: 40px !important;
  }
  .template-grid
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .resume-zoom
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    font-size: 8px !important;
  }
  .choose-template-section .styleTempOne .top-fix-box,
  .choose-template-section .styleTempTwo .top-fix-box,
  .choose-template-section .styleTempThree .top-fix-box,
  .choose-template-section .styleTempFour .top-fix-box {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 8px !important;
  }
  .choose-template-section .resume-zoom.styleTempOne .mb-3.py-2.styled-bg {
    padding-bottom: 0px !important;
  }
  .choose-template-section
    .resume-template-box.temp-3
    .parent-col.pt-4.pr-3.pl-3 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .template-grid .styleTempFour .resume-heading,
  .template-grid .styleTempTwo .resume-heading {
    padding: 2px;
    font-size: 3px !important;
    padding-bottom: 1px;
  }
  .template-grid .styleTempFour .profile-content .resume-social-info {
    padding-left: 12px;
  }
  .template-grid .styleTempFive .slide-bg-clr.top-fix-box {
    padding: 8px;
  }
  .template-grid .styleTempFive .top-fix-box {
    margin-bottom: 2px;
  }
  .template-grid .styleTempFour .resume-section .paragraph,
  .template-grid .styleTempFour .resume-section .paragraph1,
  .template-grid .styleTempFive .resume-section .paragraph,
  .template-grid .styleTempFive .resume-section .paragraph1 {
    padding-left: 15px;
  }
  .resume-pre-dialog .modal-content {
    height: 90vh !important;
    overflow-y: scroll;
  }
  form input,
  .check-vd-input input,
  form select {
    background-color: #fff !important;
    color: #000 !important;
  }
  form.resume-contact-form .custom-check-container .checkmark {
    top: -2px;
  }
  .modal-dialog.resume-pre-dialog h3 {
    font-size: 1.4rem;
  }
  /* final-resume-css */
  .final-resume-box .styleTempDBEight .dummy-img {
    height: 100px !important;
  }
  .final-resume-box .styleTempDBTwelve .profile-pic .addNew .dummy-img {
    height: 55px !important;
    width: 55px !important;
  }
  .final-resume-box .styleTempDBSix .dummy-img {
    height: 70px !important;
    width: 70px !important;
  }
  .final-resume-box .styleTempDBTwelve .profile-pic .dummy-img-box {
    padding-left: 15px;
  }
  .final-resume-box .resume-heading {
    font-size: 10px !important;
  }
  .final-resume-box .paragraph,
  .final-resume-box .resume-template-box ul {
    font-size: 8px !important;
    line-height: normal !important;
  }
  .final-resume-box .parent-col:nth-of-type(1) .parent-right {
    padding: 15px;
    padding-bottom: 0px;
  }
  .final-resume-box .temp-4-pd .parent-col:nth-of-type(3),
  .final-resume-box .temp-4-pd .parent-col:nth-of-type(2) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .final-resume-box .resume-icon svg {
    height: 8px;
    width: 8px;
  }
  .final-resume-box .styleTempDBEight .parent-col:nth-of-type(2),
  .final-resume-box .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2),
  .final-resume-box .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 70px !important;
  }
  .final-resume-box .upload-sign {
    line-height: normal;
    font-size: 8px;
  }
  .final-resume-box .styleTempDBTwelve .top-section.parent-container {
    padding: 15px 0px !important;
    padding-bottom: 15px !important;
  }
  .final-resume-box .styleTempDBTen .temp-4-pd .parent-left,
  .final-resume-box .styleTempDBEleven .temp-4-pd .parent-left {
    padding: 15px !important;
  }
  .final-resume-box .styleTempDBTen .dummy-img,
  .final-resume-box .styleTempDBEleven .dummy-img,
  .final-resume-box .styleTempDBTen .dummy-img-box .addNew,
  .final-resume-box .styleTempDBEleven .dummy-img-box .addNew,
  .final-resume-box .template-section .styleTempDBTen .dummy-img,
  .final-resume-box .template-section .styleTempDBEleven .dummy-img,
  .final-resume-box .resume-preview .styleTempDBTen .dummy-img,
  .final-resume-box .resume-preview .styleTempDBEleven .dummy-img {
    height: 80px !important;
  }
  .final-resume-box .styleTempDBEleven .postition-bg-style.fade-bg {
    height: 200px;
    width: 63%;
  }
  .final-resume-box
    .styleTempDBTen
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right,
  .final-resume-box
    .styleTempDBEleven
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right {
    padding-left: 33%;
  }
  .final-resume-box .styleTempDBNine .dummy-img {
    height: 70px !important;
  }
  .final-resume-box .styleTempDBNine .temp-4-pd .parent-left {
    padding-top: 5px;
  }
  .final-resume-box
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    padding-left: 0px;
    padding-top: 10px;
  }
  .final-resume-box .styleTempDBSix .parent-right .name-title,
  .final-resume-box .styleTempOne .name-title {
    top: 5px;
    font-size: 1.3rem !important;
  }
  .final-resume-box .styleTempOne .profile-img-box {
    margin-bottom: 0px;
  }
  .final-resume-box .styleTempOne .top-fix-box,
  .final-resume-box .styleTempTwo .top-fix-box,
  .final-resume-box .styleTempThree .top-fix-box,
  .final-resume-box .styleTempFour .top-fix-box {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .final-resume-box .parent-col:nth-of-type(2) {
    padding-left: 15px;
  }
  .final-resume-box .styleTempOne .parent-col:nth-of-type(2) {
    padding-top: 15px !important;
  }
  .final-resume-box
    .styleTempDBSix
    .parent-col:nth-of-type(1)
    .parent-left
    .profile-pic {
    top: 44px;
  }
  .final-resume-box
    .styleTempDBSix
    .resume-content-box
    .parent-col:nth-of-type(2) {
    padding-top: 30px !important;
  }
  .final-resume-box .styleTempDBSeven .dummy-img,
  .final-resume-box .styleTempOne .dummy-img,
  .final-resume-box .styleTempFive .dummy-img {
    height: 70px !important;
    width: 70px !important;
  }
  .final-resume-box .styleTempDBSeven .dummy-img-box {
    top: 15px;
  }
  .final-resume-box .styleTempDBSeven .parent-col:nth-of-type(2) {
    padding-top: 45px !important;
  }
  .final-resume-box .styleTempOne .styled-bg {
    padding: 7px !important;
  }
  .final-resume-box .styleTempTwo .parent-col:nth-of-type(2),
  .final-resume-box .styleTempFour .parent-col:nth-of-type(2) {
    padding-top: 5px !important;
  }
  .final-resume-box .styleTempTwo .top-fix-box {
    padding-left: 6px !important;
    padding-right: 11px !important;
  }
  .final-resume-box .temp-3 .socail-info {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .final-resume-box .styleTempThree .top-fix-box {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .final-resume-box .styleTempThree .dummy-img,
  .final-resume-box .styleTempFour .dummy-img {
    height: 55px !important;
    width: 55px !important;
  }
  .final-resume-box div#socialInfo p {
    margin-bottom: 2px !important;
  }
  .final-resume-box .styleTempThree .profile-img-box {
    margin-bottom: 0px;
  }
  .final-resume-box .styleTempThree .mb-3.py-2.px-4.name-bg {
    margin-bottom: 0px !important;
  }
  .final-resume-box .styleTempThree .contact-info-box {
    padding-left: 15px;
  }
  .final-resume-box .styleTempThree .profile-img-box {
    margin-right: 15px;
  }
  .final-resume-box .resume-content-three .parent-col:nth-of-type(2) {
    padding: 15px;
  }
  .final-resume-box .socail-info p {
    position: relative;
    padding-left: 15px;
  }
  .final-resume-box .styleTempFour .top-fix-box {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .final-resume-box .styleTempFour .profile-img-box {
    margin-bottom: 0px;
  }
  .final-resume-box .styleTempFour .resume-section .paragraph,
  .final-resume-box .styleTempFour .resume-section .paragraph1,
  .final-resume-box .styleTempFive .resume-section .paragraph,
  .final-resume-box .styleTempFive .resume-section .paragraph1 {
    padding-left: 30px;
  }
  .final-resume-box .styleTempFive .slide-bg-clr.top-fix-box {
    padding: 15px;
  }
  .final-resume-box .styleTempFive .profile-img-box {
    margin-bottom: 0px;
  }
  .resume-slide-box .resume-preview {
    display: block;
  }
  .bottom-fix-toolbar .next-btn .site-btn {
    min-width: 100px;
    font-size: 8px !important;
    padding: 5px !important;
    font-weight: 600 !important;
  }
  .drop-down-template.pdc p {
    font-size: 8px !important;
  }
  .img-icon.template-icon img {
    height: 14px;
  }
  .drop-down-template .color-selector-radio {
    height: 15px;
    width: 15px;
  }
  .resume-slide-box {
    z-index: 3;
  }
  .drop-down-template p.mb-1.bold {
    font-size: 8px;
  }
  .drop-down-template {
    margin-bottom: 0px;
    padding: 2px 8px !important;
  }
  .custom-left-sec .custom-col {
    margin-right: 0px;
  }
  .bottom-fix-toolbar .container {
    padding-left: 0px;
    padding-right: 8px;
  }
  .drop-down-template.pdc {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .final-structure-container.container.pt-5.text-white {
    padding-top: 0px !important;
  }
  .scroll-resume-box .resume-template-box .name-title {
    padding-left: 10px;
}
.scroll-resume-box .styleTempDBSeven .parent-col:nth-of-type(2), .scroll-resume-box .styleTempDBNine .parent-col:nth-of-type(2) {
    padding-top: 55px !important;
}
.scroll-resume-box .resume-preview .resume-zoom .dummy-img {
    height: 55px !important;
    width: 55px !important;
}
.scroll-resume-box .styleTempFour .profile-content .resume-social-info {
    padding-left: 15px;
}
.scroll-resume-box .resume-template-box .name-title {
    padding-left: 0px;
}
}
/* resume test-css */
@media screen and (max-width: 576px) {
  .range-text {
    font-size: 9px;
  }
  .lang-range-slider {
    width: 90%;
  }
  .proficency-range .MuiSlider-markLabel {
    top: -20px !important;
  }
  .range-text .label-value {
    left: -25px;
    top: 50px;
  }
  .profile-img-resume-contact {
    flex-direction: column;
  }
  .profile-img-box {
    margin-right: 0px;
    margin-bottom: 25px;
  }
  .profile-img-box .w-100 {
    width: 50% !important;
  }
  .resume-slide-box {
    bottom: 90px;
  }
  .drop-down-template {
    padding: 5px 8px !important;
    padding-right: 18px !important;
  }

  .active-slide .label-name,
  .active-slide .label-value {
    font-size: 8px;
    font-weight: 700;
  }
}
